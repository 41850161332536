.live-title {
    font-size: 2rem;
}

.events {
    .event {
        display: flex;
        .bullet {
            margin-top: 1rem;
            font-size: 20px;
            min-width: 20px;
        }
        h3 {
            margin-top: 1rem;
            font-size: 20px;
            font-family: "Comic Neue", sans-serif;
        }
    }
}

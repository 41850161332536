@use "../abstracts/variables" as *;

//.image-transition {
//    opacity: 0;
//}

//.loaded {
//  opacity: 1;
//}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $ldm-grey; /* Adjust the background color as desired */
    height: 100%;
    width: 320px;
    margin-right: 2rem;
}

.loadingSpinner {
    box-sizing: border-box;
    display: block;
    width: 40px;
    height: 40px;
    border: 4px solid #888; /* Adjust the spinner color as desired */
    border-top-color: #000; /* Adjust the spinner color as desired */
    border-radius: 50%;
    animation: spinner-animation 1s linear infinite;
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

main {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: $header-offset;

    .main-container {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        justify-content: space-between;
    }
}

section {
    scroll-margin-top: 80px;
    padding-top: 3rem;

    &#About {
        scroll-margin-top: 120px;
        margin-top: 0;
        padding-top: 0;
    }
}

#records-anchor,
#live-anchor {
    display: block;
    position: relative;
    top: -$header-offset;
    visibility: hidden;
}

#about-anchor {
    display: block;
    position: relative;
    top: -$header-offset;
    visibility: hidden;
}

@media (max-width: 600px) {
    main {
        margin-top: $header-offset-mobile;
    }

    // #Records,
    // #About,
    // #Live {
    //     scroll-margin-top: 48px;
    // }

    .spinner-container {
        margin-right: 0rem;
        margin-bottom: 1rem;
        width: 100%;
    }
}

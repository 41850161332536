@use "../abstracts/variables" as *;

.about-text a {
    font-family: "Comic Neue", sans-serif;
    font-size: 20px;
    color: $ldm-orange;
}

.about-text {
    line-height: 1.2;
    a {
        position: relative;

        text-decoration: none;

        /* Pseudo-element to create the underline */
        &::before {
            content: "";
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1.5px;
            background-color: $ldm-orange; /* blue color */
            transition: all 0.2s ease-in-out; /* Transition the width property */
        }

        /* Hover effect to expand the underline */
        &:hover::before {
            opacity: 1;
        }
    }
}

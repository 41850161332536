@use "../abstracts/variables" as *;

header {
    position: fixed;
    background-color: $ldm-white;
    width: 100%;
    height: $header-offset;
    display: flex;
    justify-content: center;
    transition: 0.3s ease-in-out all;
    z-index: 999;

    // mask-image: linear-gradient(
    //     to bottom,
    //     rgba(30, 87, 153, 1) 0%,
    //     rgba(30, 87, 153, 1) 1%,
    //     rgba(30, 87, 153, 1) 9%,
    //     rgba(30, 87, 153, 1) 92%,
    //     rgba(30, 87, 153, 0) 100%
    // );

    // -webkit-mask-image: linear-gradient(
    //     to bottom,
    //     rgba(30, 87, 153, 1) 0%,
    //     rgba(30, 87, 153, 1) 1%,
    //     rgba(30, 87, 153, 1) 9%,
    //     rgba(30, 87, 153, 1) 92%,
    //     rgba(30, 87, 153, 0) 100%
    // );
    .header-container ul li .menuLinks.menuLinksWhite {
        color: $ldm-white;

        &.active {
            color: $ldm-orange;
        }
    }

    .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ul {
            display: flex;
            li {
                margin-left: 1rem;
                margin-top: 8px;
                font-size: 24px;
                cursor: pointer;
                color: $ldm-black;
                transition: 0.3s ease-in-out all;
                .menuLinks.active {
                    color: $ldm-orange; /* Replace with the color you want for the active link */
                }
                .menuLinks {
                    color: $ldm-black;
                    transition: 0.3s ease-in-out all;

                    text-decoration: none;
                    @media (hover: hover) {
                        &:hover {
                            color: $ldm-orange;
                        }
                    }
                }
            }
        }

        @keyframes recordMove {
            0% {
                margin-bottom: 16px;
            }
            50% {
                margin-bottom: 0px;
            }

            100% {
                margin-bottom: 16px;
            }
        }

        @keyframes recordMoveMobile {
            0% {
                margin-bottom: 16px;
            }
            50% {
                margin-bottom: 0px;
            }

            100% {
                margin-bottom: 16px;
            }
        }

        .logo-container {
            position: relative;
            height: 80px;
            margin-left: -10px;
        }

        .logo {
            position: absolute;
            width: 68px;
            bottom: 0;
        }

        .record-logo {
            width: 68px;
            position: absolute;
            animation-name: recordMove;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            bottom: 0;
        }
    }
}

.header-scroll {
    background-color: $ldm-black;
}

.headerFade {
    background-color: $ldm-black;
    transition: 0.3s ease-in-out all;
    .header-container {
        ul {
            li {
                .menuLinks {
                    color: $ldm-white;
                }
            }
        }
    }
}

@media (max-width: 600px) {
    // header .header-container .logo-container {
    //     height: 108px;
    // }
    // header .header-container .logo {
    //     width: 90px;
    // }

    // header .header-container .record-logo {
    //     width: 90px;
    //     animation-name: recordMoveMobile;
    // }

    // header {
    //     height: $header-offset-mobile;
    // }
}

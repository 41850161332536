@font-face {
    font-family: Lewis Does Mixing;
    src: url("../../fonts/Ldm-Regular.otf") format("opentype");
}

h1,
h2,
h3,
h4,
h5,
li,
a,
span {
    font-family: "Lewis Does Mixing", sans-serif;
}

p,
div {
    font-family: "Comic Neue", sans-serif;
    font-size: 20px;
}

@use "../abstracts/variables" as *;

footer {
    margin-top: 4rem;
    width: 100%;
    display: flex;
    justify-content: center;

    .footer-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        a {
            margin-top: auto;
            margin-bottom: auto;
            font-size: 24px;
            text-decoration: none;
            color: $ldm-black;
            transition: 0.3s ease-in-out all;
        }

        .footer-top {
            display: flex;
            padding: 0 32px;

            a {
                margin-top: auto;
                margin-bottom: auto;
                font-size: 24px;
                text-decoration: none;
                color: $ldm-black;
                transition: 0.3s ease-in-out all;
            }
            a:hover {
                color: $ldm-orange;
            }
        }

        .footer-bottom {
            margin: 0 auto;
            padding: 32px;
            text-align: center;

            a:hover {
                color: $ldm-orange;
            }
        }

        // @keyframes recordMove {
        //     0% {
        //         margin-bottom: 39px;
        //     }
        //     50% {
        //         margin-bottom: 12px;
        //     }

        //     100% {
        //         margin-bottom: 39px;
        //     }
        // }

        .logo-container {
            position: relative;
            width: 100px;
            height: 135px;
            padding: 0 32px;
            display: flex;
            justify-content: center;
        }

        .logo {
            position: absolute;
            width: 100px;
            bottom: 0;
        }

        .record-logo {
            width: 100px;
            position: absolute;
            animation-name: recordMove;
            animation-duration: 4s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-in-out;
            bottom: 0;
        }
    }
}

@media (max-width: 600px) {
    footer .footer-container .logo-container {
        height: 108px;
    }
    footer .footer-container .logo {
        width: 90px;
    }

    footer .footer-container .record-logo {
        width: 90px;
        animation-name: recordMoveMobile;
    }
}

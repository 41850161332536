@use "../abstracts/variables" as *;

.record-section-title {
    font-size: 2rem;
}

.record-loading {
    margin: 1rem 0;
    display: flex;
    align-items: center;

    .spinner {
        display: inline-block;
        height: 26px;
        width: 26px;
        background-image: url(../../static/images/logos/record-1.png);
        animation-name: loadingSpin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 0;
    }

    @keyframes loadingSpin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.record-single {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 2rem auto 0;
    .record-top {
        display: flex;
    }

    .record-info {
        margin: 0rem 1rem 0 0;
        h3 {
            margin-bottom: 16px;
            font-size: 24px;
        }

        .record-bio {
            margin-bottom: 1rem;
        }

        .record-link {
            color: #ff5c00;
            display: flex;
            text-decoration: none;
            justify-content: flex-start;
        }
    }

    .record-player {
        width: 100%;
        height: 100%;
    }

    .record-tags {
        display: flex;
        flex-wrap: wrap;

        .record-tag {
            border-radius: 3rem;
            padding: 0.5rem;
            padding-top: 0.75rem;
            background-color: #deffc5;
            margin-right: 1rem;
            margin-top: 1rem;
            text-transform: uppercase;
            font-size: 1rem;
        }
    }
}

.record-single {
    img {
        max-width: 320px;
        margin-right: 32px;
        // height: 100%;
        background-color: $ldm-grey;
        // width: 100%;
    }

    .listen-text {
        margin: auto 0;
        margin-top: 4px;
        text-align: left;
    }

    .material-symbols-outlined {
        font-variation-settings:
            "FILL" 1,
            "wght" 400,
            "GRAD" 200,
            "opsz" 48;
        margin-left: -4px;
        width: 24px;
    }
}

@media screen and (max-width: 600px) {
    .record-top {
        flex-direction: column;
    }

    .record-single img {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 1rem;
        height: 100%;
    }

    .record-single {
        .record-info {
            margin-top: 1rem;
            margin-right: 0;
        }
    }
}
